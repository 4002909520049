import React from "react";
import Head from "next/head";
import { Header, Nav } from "components";

const Home = () => (
  <div>
    <Head>
      <title>Welcome!</title>
    </Head>

    <Header />

    <div className="hero">
      <h1 className="title">Welcome!</h1>
      <h2 className="description">We think all code rocks!</h2>
      <p className="description">
        We're putting things together currently, so stay tuned.
      </p>
    </div>

    <style jsx>{`
      .hero {
        width: 100%;
        color: #333;
      }
      .title {
        margin: 0;
        width: 100%;
        padding-top: 80px;
        line-height: 1.15;
        font-size: 48px;
      }
      .title,
      .description {
        text-align: center;
      }
    `}</style>
  </div>
);

export default Home;
