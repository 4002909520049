import React from "react";
import Head from "next/head";
import { useRouter, NextRouter } from "next/router";
import style from "./style.module.css";

export const handleLogoClick = (router: NextRouter) => () => {
  router.push("/");
};

export const Logo = () => {
  const router = useRouter();
  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:600"
          rel="stylesheet"
          type="text/css"
          key="logo-font"
        />
      </Head>
      <div className={style.logo} onClick={handleLogoClick(router)}>
        <span className={style.code}>code</span>
        <span className={style.dot}>.</span>
        <span className={style.rocks}>rocks</span>
      </div>
    </>
  );
};
