import React from "react";
import { Logo, Nav } from "components";
import style from "./style.module.css";

export const Header = () => {
  return (
    <header className={style.header}>
      <Logo />
      <Nav />
    </header>
  );
};
