import React from "react";
import Link from "next/link";
import style from "./style.module.css";

export const Nav = () => {
  return (
    <nav className={style.nav}>
      <Link href="https://github.com/joshcoody">
        <a target="_blank">Github Profile</a>
      </Link>
    </nav>
  );
};
